export interface VoucherData {
  rewardType: string;
  minOrderValue: number | null;
  [k: string]: any;
}

export interface VoucherCode {
  code: string;
  id: string;
  oneTimeUse: boolean;
  voucherId: string;
}

interface codeType {
  id: string;
  code: string;
  oneTimeUse: boolean;
  scanData: null | { scanType: string; barCodeStandard: string };
  voucherId: string;
}

export interface VoucherV2 {
  id: string;
  name: string;
  hasPin: boolean;
  redemptionLimit: 'ONCE' | 'UNLIMITED';
  expiryDate: string | null;
  redemptionPeriod?: { days: number };
  rewardType: string;
  voucherData: VoucherData;
  brandId?: string;
  outletIds: string[];
  voucherBrandId: string;
  createdAt: string;
  withSaleAmount: string;
  unallowedModules: string[];
  active: boolean;
  issuanceCap: number | null;
  surveyRedemptionTimeGap: { hours: number } | null;
  unusable: boolean;
  platform: { platform: string } | { url: string } | null;
  shouldCollectPhoneNumber: string;
  schedule?: DayType[] | null;
  startDate: string | null;
  codes?: codeType[];
  voucherIssuanceCount?: string;
  issuanceType?: IssuanceTypeEnum;
  redemptionRate?: string;
  salesamount?: string;
  mailProcessed?: string;
  mailOpen?: string;
  mailBounced?: string;
  totalActive: number;
  totalIssued: number;
  totalRedeemed: number;
  remainingCap: number | null;
  issuanceMetaData?: {
    drawDate: string;
    numberOfWinners: number;
    isCompleted: boolean;
  } | null;
  remainingVoucherCodes?: number;
  startDateText?: string;
  isDraft?: boolean;
}

export interface Voucher {
  id: string;
  name: string;
  hasPin: boolean;
  redemptionLimit: 'ONCE' | 'UNLIMITED';
  expiryDate: string | null;
  redemptionPeriod?: { days: number };
  rewardType: string;
  voucherData: VoucherData;
  brandId?: string;
  outletIds: string[];
  voucherBrandId: string;
  createdAt: string;
  withSaleAmount: string | null;
  unallowedModules: string[];
  active: boolean;
  issuanceCap: number | null;
  unusable?: boolean;
  shouldCollectPhoneNumber: string | null;
  platform?: { platform: string } | { url: string };
  expired: boolean;
  pastIssuanceCap: boolean;
  noMoreVoucherCodes: boolean;
  codes: VoucherCode[];
  issuanceType: IssuanceTypeEnum;
  issuanceMetaData?: {
    drawDate: string;
    numberOfWinners: number;
    isCompleted: boolean;
  } | null;
  pageConfig?: any;
  translations?: any;
}

export enum IssuanceTypeEnum {
  LUCKY_DRAW = 'luckyDraw',
  IMMEDIATE = 'immediate',
}

export interface VoucherInstance {
  id: string;
  voucherId: string;
  customerDataId: string;
  redeemOutletId: string | null;
  redeemTime: Date | null;
  redeemMetadata: any;
  expiryDateText: string | null;
  address1: string | null;
  city: string | null;
  code?: string;
  voucherCodeId: string;
  scanData: { scanType: string; barCodeStandard: string };
  createdAt: string;
  expiryDate: string;
}

interface BrandCategory {
  partner: string;
  category: string;
  hasAddCategory: boolean;
  addCategory: string[];
}

export interface ThirdPartyLink {
  id: string;
  categoryId: string;
  type: string;
  link: string;
  category: string;
  logo: string;
}

export interface Brand {
  id: string;
  name: string;
  serviceOptions: string[];
  category: BrandCategory[];
  cuisine: string | null;
  logoId: string | null;
  logoName: string | null;
  logoUrl: string | null;
  menuLink: string;
  website: string;
  status: string;
  thirdPartyLinks: ThirdPartyLink[];
  defaultVoucherPinCode: string | null;
  accountId: string;
  createdBy: string;
  updatedBy: string;
  region?: string;
}

export interface VoucherOutlet {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  distance: number;
}

export interface VoucherCategory {
  id: string;
  category: string;
  status: string;
  linkType: string;
  categoryId: string;
}

export interface MicrositeTheme {
  backgroundColor: string;
  fontColor: string;
  fontType: string;
  headerBgColor: string;
  headerTextColor: string;
  highlightColor: string;
  reviewStarColor: string;
  titleFontType: string;
}

export interface MicrositeConfigDetails {
  micrositeTheme: MicrositeTheme;
  socialIconOrder: string[];
}

export interface MicrositeData {
  name: string;
  thirdPartyLinks: ThirdPartyLink[];
  serviceOptions: string[];
  website: string;
  status: string;
  logoUrl: string;
  micrositeStatus: boolean;
  micrositeConfigDetails: MicrositeConfigDetails;
  isFoodDelivery: boolean;
  isReservation: boolean;
  micrositeBlacklistComponents: string[];
  micrositeFoodMenuId: string;
  micrositePixelCode: string;
  micrositePixelId: string;
  micrositeSurveyId: string;
  outletCount: number;
  micrositebuttonAttributeConfig: TMicrositebuttonAttributeConfig[];
}

export type TMicrositebuttonAttributeConfig = { name: string; type: string; value: string; status: boolean };

export interface DefaultButtonType {
  id: string;
  name: string;
  value: string;
  type: string;
  status: boolean;
}

export type InquiryFormConfigGeneral = {
  introMessage: string;
  showBrandName: boolean;
  showIntroMessage: boolean;
  showTitle: boolean;
  title: string;
  showLogo: boolean;
  showPoweredBy: boolean;
  showHeroBanner: boolean;
  termsConditions: string;
  requiredTermsConditions: boolean;
  showTermsConditions: boolean;
};

type InquiryFormConfigStyling = {
  backgroundColor: string;
  fontColor: string;
  fontType: string;
  headerBgColor: string;
  headerTextColor: string;
  highlightColor: string;
  reviewStarColor: string;
  titleFontType: string;
  logoUrl: string;
  logo: any;
  heroBanner: any;
};

type InquiryFormConfig = {
  general: InquiryFormConfigGeneral;
  styling: InquiryFormConfigStyling;
  thirdPartyLinks: ThirdPartyLink[];
  thirdPartyLinksOrder: any;
};

export type InquiryType = {
  created_at: string;
  created_by: string;
  id: string;
  inquiry_form_id: string;
  is_active: boolean;
  name: string;
  updated_at: string;
  updated_by: string;
  master_inquiry_type_id: string;
};

type InquiryFormLocationAddress = {
  address1: string;
  city: string;
};

export type InquiryFormOutlet = {
  id: string;
  location: InquiryFormLocationAddress[];
  name: string;
};

export type InquiryFormQuestionType = {
  id: string;
  isCustom: boolean;
  isRequired: boolean;
  questionText: string;
  questionType: string;
  status: boolean;
  types: Record<string, string>[];
  responseText: string;
  options: string[];
  fileList: InquiryFormFileType[];
  showLoading: boolean;
  col?: number;
};

export type InquiryFormFileType = {
  id: string;
  url: string;
  name: string;
  size?: number;
};

export interface InquiryFormData {
  region: string;
  brandName: string;
  brandSlug: string;
  id: string;
  config: InquiryFormConfig;
  inquiryTypes: InquiryType[];
  outlets: InquiryFormOutlet[];
  questionTypes: InquiryFormQuestionType[];
}

export interface ReviewDetails {
  momosReviewId: string;
  platform: string;
  reviewerDetails: {
    displayName: string;
    profilePhotoUrl: string;
  };
  message: string;
  createdAt: string;
  updatedAt: string;
  transMessage: string;
  rating?: string | number;
}

export interface Staff {
  profile_url: string | null;
  name: string | null;
  id: string | null;
}

export interface CategoriesConfigType {
  status: boolean;
  categoryId: string;
  promotionalMessage: string;
}

export interface MicrositeCategoriesConfigType {
  foodDelivery: CategoriesConfigType[];
  reservation: CategoriesConfigType[];
}

export interface MicrositeConfig {
  micrositePixelId?: string | null;
  micrositeTheme: MicrositeTheme;
  reservationFlag: boolean;
  foodDeliveryFlag: boolean;
  menuFlag: boolean;
  micrositeCategoriesConfig: MicrositeCategoriesConfigType;
  micrositeBlacklistComponents?: string[];
}

export interface SurveyOutlet {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
  distance: number | null;
  thirdPartyLinks?: ThirdPartyLink[];
  gmbReviewLink?: string;
  latLong: number[];
}

export interface SurveyQuestion {
  id: string;
  type: string;
  sortOrder: string;
  format?: string;
  sentiment?: string;
  config?: Record<string, any>;
  surveyQuestionId?: string;
  questionData?: {
    subtext?: string;
    text?: string;
    rating?: {
      max?: number;
      min?: number;
    };
  };
}

export interface Survey {
  questions: SurveyQuestion[];
  id: string;
  outlets: Outlet[];
  name: string;
  version?: string;
  voucher: Record<string, any>;
  voucherIds?: string[];
  brandId: string;
  surveyData: {
    surveyTheme: {
      brandColor: string;
      font?: string;
      backgroundColor?: string;
    };
    [x: string]: any;
  };
  surveyPages?: Page[];
  settings: SurveySetting;
}

export interface SurveySubmissionAnswerType {
  answer: string | number;
  detailedAnswer: string;
  experienceData: Record<string, string>;
  media?: any;
}

export interface SurveySubmissionType {
  questionId: string;
  answerData: SurveySubmissionAnswerType;
}

export interface SurveySubmissionPayloadType {
  name: string;
  email: string;
  phoneNumber?: string;
  outletId: string;
  serviceOption: {
    type: string | null;
    details: {
      platform: string | null;
      date?: string;
      orderId?: string;
      source?: string;
      sourceType?: string;
      payment?: string;
      receiptId?: string;
      metaData?: any;
    };
  };
  submissions: SurveySubmissionType[];
  timezoneOffset: number;
  surveySubmissionType?: string;
  orderItemDetails?: Record<string, any> | null;
}

export interface SurveySetting {
  positiveRating: number[];
  negativeRating: number[];
  showProgressBar?: boolean;
  layout?: string;
  itemRejectionList?: string[];
}

export interface SurveyData {
  surveyData: Survey;
  categories: ThirdPartyLink[];
  brandDetails: Brand;
  micrositeConfig: MicrositeConfig;
  smsFeatureEnabled?: boolean;
}

export interface SurveyResultType {
  voucherDispenseError?: boolean;
}

export interface Outlet {
  thirdPartyLinks?: ThirdPartyLink[];
  name: string;
  count: number;
  serviceOptions?: string[];
  address1?: string;
  city: string;
  distance: number | null;
  address: string;
  state: string;
  openingHours: OpeningHourType[];
  id: string;
  specialHours: OpeningHourType[];
  contactInfo?: {
    phone: string;
    pin: string;
  };
  avgRating?: number;
  mapUrl?: string;
  latLong: number[];
  pinCode?: string;
  address2?: string;
  gmbReviewLink?: string;
  gmbFkLocationStatus?: string;
}

export interface OpeningHourType {
  end_time: string;
  id: string;
  platform: string;
  start_time: string;
  type: string;
  weekday: number;
  end_date: string;
  is_open: boolean;
  start_date: string;
}

export interface SpecialHourType {
  day: string;
  startTime: string;
  endTime: string;
  isOpen: boolean;
  platform: string;
  type: string;
}

export interface OperatingHourType {
  type: string;
  isOpen: boolean;
  startTime: string;
  endTime: string;
  dataList: any;
  day: string;
  platform: string;
}

export interface DesktopViewWrapProps {
  $fontType?: string;
  $fontColor?: string;
  $headerBgColor?: string;
  $backgroundColor?: string;
  $headerTextColor?: string;
  $titleFontType?: string;
}

export interface DayType {
  day: string;
  isOpen: boolean;
  startTime: string;
  endTime: string;
  dataList: [];
}
export interface SubscribeButtonWrapProps {
  fontColor?: string;
  fontType?: string;
  highlightColor?: string;
}

export interface SubscribeRequestParams {
  email: string;
  phoneNumber?: string;
  name?: string;
  dob?: string;
}

export interface ITnc {
  brandId: string;
  type: string;
  format: string;
  fileUrl: string;
  content: string;
  thirdPartyLink: string;
  brandName: string;
}

export enum EServiceOption {
  DELIVERY = 'DELIVERY',
  TAKE_AWAY = 'TAKE_AWAY',
}

export type TMicrositeSubscribeButtonConfig = {
  title: 'Subscribe';
  fontType: 'Barlow-Bold';
  fontColor: '#2b6d6e';
  highlightColor: '#d84338';
};

export enum ESubscribeModalFieldType {
  text = 'text',
  email = 'email',
  tel = 'tel',
  date = 'date',
}

export enum ESubscribeModalFieldCategory {
  DEFAULT = 'DEFAULT',
}

export type TMicrositeSubscribeModalConfig = {
  body: string;
  title: string;
  fields: {
    name: string;
    type: ESubscribeModalFieldType;
    status: boolean;
    category: ESubscribeModalFieldCategory;
    isRequired: boolean;
  }[];
  consent: boolean;
  fontType: string;
  hideLogo: boolean;
  fontColor: string;
  modalReappear: {
    onPageLoad: boolean;
  };
  highlightColor: string;
  footerButtonTitle: string;
  footerButtonFontType: string;
  unSubscribeModalConfig: {
    title: string;
    description: string;
    footerButtonTitle: string;
  };
  footerButtonBackgroundColor: boolean;
  voucherDetails: {
    currency: string;
    amountOff: string;
    minOrderValue: string;
    hasBrandPinCode: boolean;
  };
};

export enum EStatus {
  ENABLED = 'ENABLED',
}

export interface BrandDetail {
  name: string;
  thirdPartyLinks: ThirdPartyLink[];
  serviceOptions: EServiceOption;
  website: string; // 'https://momos.io/';
  status: EStatus;
  logoUrl: string;
  micrositeStatus: boolean;
  micrositeConfigDetails: MicrositeConfigDetails;
  micrositebuttonAttributeConfig: TMicrositebuttonAttributeConfig[];
  micrositeBlacklistComponents: string[];
  micrositeCategoriesConfig: MicrositeCategoriesConfigType;
  micrositeFoodMenuId: string;
  micrositePixelId: string;
  micrositePixelCode: string;
  micrositeSurveyId: string;
  micrositeReviewsConfig: null;
  outletCount: number;
  isFoodDelivery: boolean;
  isReservation: boolean;
  micrositeIsSubscribe: boolean;
  micrositeSubscribeButtonConfig: TMicrositeSubscribeButtonConfig;
  micrositeSubscribeModalConfig: TMicrositeSubscribeModalConfig;
}

export type Page = {
  pageKey: string;
  pageName: string;
  skipPage?: boolean;
  showSkipPageToggle?: boolean;
  isCustomPage: boolean;
  sortOrder: number;
  usePage: boolean;
  hasQuestion: boolean;
  value: {
    fieldKey: string;
    fieldValue?: FieldValueType;
    fieldType?: string;
    type?: string;
    show?: boolean;
    label?: string;
    options?: Option[];
    placeholder?: string;
    showRequired?: boolean;
    button?: string | any;
  }[];
  questions: Question[];
  bgImageUrl?: string;
  selectedValue?: any; //TODO* type
};

export type MediaType = { mediaUrl: string; mediaId: string; mimetype?: string };

export type FieldValueType = string | MediaType | Option[] | string[] | any | undefined;

export type Question = {
  id?: string;
  surveyQuestionId?: string;
  config?: any;
  type: string;
  questionText: string;
  questionDataType: string;
  questionOrder: number;
  isRequired: boolean;
  isActive: boolean;
  isCustom: boolean;
};

export interface Option {
  key: string;
  value: string;
  label?: string;
  icon?: any;
  show?: boolean;
  showTextbox?: boolean;
  iconName?: string;
  name?: string;
  address?: string;
  iconUrl?: string;
  src?: string;
}

export const RATING = {
  STAR_1_3: '1_3',
  STAR_1_5: '1_5',
  STAR_1_10: '1_10',
  EMOJI: 'emoji',
  THUMBS: 'thumbs',
};

export const PAGE_KEYS = {
  WELCOME_PAGE: 'welcome_page',
  TYPE_OF_ORDER: 'type_of_order',
  CHOOSE_LOCATION: 'choose_location',
  DELIVERY_PLATFORM: 'delivery_platform',
  RATINGS: 'ratings',
  EXPERIENCE_DETAILS: 'experience_details',
  EXPERIENCE_POSITIVE: 'experience_positive',
  EXPERIENCE_NEGATIVE: 'experience_negative',
  STAFF_LIST: 'staff_list',
  STAFF_RATING: 'staff_rating',
  PERSONAL_INFORMATION: 'personal_information',
  BOOST_PAGE: 'boost_page',
  THANK_YOU: 'thank_you',
};
