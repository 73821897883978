import React from 'react';
import styled from 'styled-components';

type Size = 'small' | 'medium' | 'large';
interface VoucherHeaderProps {
  logo?: string | null;
  title: string;
  className?: string;
  size?: Size;
  showTitle?: boolean;
}

const VoucherHeader = ({ logo, title, className = '', size = 'medium', showTitle }: VoucherHeaderProps) => {
  return (
    <VoucherHeaderWrap className={className} $size={size}>
      {logo && (
        <div className="brand-logo">
          <img src={logo} alt={title} />
        </div>
      )}
      {(!logo || showTitle) && <div className="brand-name body-text">{title}</div>}
    </VoucherHeaderWrap>
  );
};

const VoucherHeaderWrap = styled.div<{ $size: Size }>`
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 20px;
  ${({ $size }) => {
    if ($size === 'small') {
      return 'border-bottom: 1px solid var(--grey-light);';
    }
  }}

  .brand-name {
    display: inline-block;
    margin-top: 1rem;
    font-size: 18px;
    color: var(--grey-dark);
    font-weight: 700;
    font-family: 'Gilroy-Medium';
  }

  .brand-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: cover;
      ${({ $size }) => {
        if ($size === 'large') {
          return `width: 200px;`;
        }
        if ($size === 'medium') {
          return `width: 105px;`;
        }
        return `width:60px;`;
      }}
    }
  }
`;

export default VoucherHeader;
